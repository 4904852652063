import React from "react";
import alan from "../../assets/assets/alan.png";
import github from "../../assets/assets/github.png";
import linkedIn from "../../assets/assets/linkedIn.png";
import youtube from "../../assets/assets/youtube.png";
import x from "../../assets/assets/x.png";
import { useTheme } from "../ThemeContext/ThemeContext"; // Import useTheme hook
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

function About() {
  const { isLightMode } = useTheme();
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation triggers only once
    threshold: 0.1, // Adjust if needed to control when the animation starts
  });

  const fadeInAnimationVariants = {
    hidden: {
      opacity: 0,
      y: 50, // Adjust for desired initial position
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 2.0, // Adjust duration as needed
        ease: "easeInOut",
      },
    },
  };

  return (
    <div
      className={`w-full h-auto px-2 pt-10 md:px-10 ${
        isLightMode ? "light-mode" : "dark-mode"
      } transition-all duration-500`}
      name="about"
      ref={ref}
    >
      {/* Header */}
      <motion.div
        className="flex gap-7 items-center p-4 md:p-[40px] md:translate-x-2"
        variants={fadeInAnimationVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        transition={{ delay: 0.1 }}
      >
        <h2 className={`text-4xl ${isLightMode ? "text-black" : "text-white"}`}>
          About
        </h2>
        <div>
          <div className="w-[188px] border border-[#334155]"></div>
        </div>
      </motion.div>

      {/* Container */}
      <div className="w-full h-[90%] flex flex-col lg:flex lg:flex-row lg:gap-12 p-4 md:p-[40px] md:translate-x-2">
        {/* Picture */}
        <motion.div
          className="w-full lg:w-[45%] h-auto flex flex-col justify-center lg:flex lg:flex-col lg:justify-center"
          variants={fadeInAnimationVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          transition={{ delay: 0.2 }}
        >
          <img
            className="w-full h-[400px] md:h-[700px] object-cover md:object-cover rounded-lg"
            src={alan}
            alt="alan"
          />
          <div className="socials flex justify-around gap-20 pt-9">
            <a href="https://github.com/Aarias11">
              <img className="w-[24px] h-[24px]" src={github} alt="github" />
            </a>
            <a href="https://www.linkedin.com/in/aarias11/">
              <img
                className="w-[24px] h-[24px]"
                src={linkedIn}
                alt="linkedin"
              />
            </a>
            <a href="https://www.youtube.com/@AlanCodes">
              <img
                className="w-[50px] h-[55px] translate-y-[-15px] translate-x-[-15px]"
                src={youtube}
                alt="youtube"
              />
            </a>
            <a href="https://twitter.com/Alancodes11">
              <img
                className="w-[24px] h-[24px] translate-x-[-25px]"
                src={x}
                alt="x"
              />
            </a>
          </div>
        </motion.div>

        {/* Personal Info */}
        <motion.div
          className={`w-full h-auto md:w-full lg:w-[50%] lg:h-screen flex flex-col text-[${
            isLightMode ? "#475569" : "#CBD5E1"
          }] ${isLightMode ? "" : ""}`}
          variants={fadeInAnimationVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          transition={{ delay: 0.3 }}
        >
          <h3
            className={`text-[28px] md:text-[40px] font-bold text-${
              isLightMode ? "black" : "#F1F5F9"
            }`}
          >
            From coding rabbit hole to mission-driven engineer
          </h3>

          <p className="pt-5">
            In 2023, I embarked on a self-taught journey into full-stack
            development, discovering a passion for building scalable solutions
            that drive positive impact. I'm constantly expanding my toolkit and
            exploring new technologies, with a focus on creating software that
            seamlessly blends design and engineering excellence.
          </p>
          <br />
          <p>
            I'm driven by the challenge of solving complex problems for
            mission-led organizations. I'm also dedicated to empowering others
            on their learning journeys, creating tutorial content for a thriving
            community of 10,000+ YouTube subscribers.
          </p>
          <br />
          <p>
            Outside of coding, you'll find me traveling, pursuing new hobbies,
            cheering on the Celtics, experimenting in the kitchen, and
            cherishing time with loved ones.
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default About;
