import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { PiMoonFill, PiSunDimFill, PiMoon, PiSun } from "react-icons/pi";
import { useTheme } from "../ThemeContext/ThemeContext"; // Import useTheme hook
import { Link } from "react-scroll";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { isLightMode, toggleMode } = useTheme(); // Destructure isLightMode and toggleMode from useTheme hook
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".toggle-container")) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);


  // Open Mobile Menu
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full sticky top-0 z-10 flex justify-center">
      <nav
        className={`w-[100%] h-auto flex justify-between items-center p-4 z-40 ${
          isLightMode ? "light-mode" : "dark-mode"
        } transition-all duration-500`}
        style={{
          backdropFilter: "blur(20px)",
          backgroundColor: isLightMode
            ? isScrolled
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(255, 255, 255, 1.0)"
            : isScrolled
            ? "rgba(19, 19, 19, 0.8)"
            : "rgba(19, 19, 19, 0.6)",
        }}
      >
        <h2
          className={`text-4xl translate-x-[10px] md:translate-x-[66px] ${
            isLightMode ? "text-black" : "text-white"
          }`}
        >
          Alan Arias
        </h2>
        <div className="fixed top-[85vh] right-4 transform flex z-50 -translate-y-1/2 p-[12px] md:p-[40px] lg:p-[72px]">
          <motion.div
            className="toggle-container"
            initial={{ y: 25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.2, duration: 0.75 }}
          >
            <motion.div
              className={`relative border-2 border-[#94A3B8] shadow-md shadow-neutral-600 rounded-full p-1 cursor-pointer ${
                isLightMode ? "bg-[#E2E8F0] border border-[#64748B]" : "bg-[#202937]"
              }`}
              onClick={handleClick}
              style={{ zIndex: 60 }}
              initial="circle"
              animate={isOpen ? "down" : "circle"}
              variants={{
                circle: { width: "60px", height: "60px", borderRadius: "70px" },
                down: {
                  width: "60px",
                  height: "50px",
                  borderRadius: "70px",
                  y: 180,
                },
              }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <motion.div
                className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center rounded-full ${
                  isLightMode ? "bg-[rgb(242,243,245)]" : "bg-[#334155]"
                }`}
                style={{ borderRadius: isOpen ? "5px" : "50%" }}
                animate={"circle"}
                variants={{
                  circle: {
                    width: "50px",
                    height: "50px",
                    borderRadius: "70px",
                    left: "50%",
                  },
                }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                {isLightMode ? (
                  <PiSunDimFill style={{ fontSize: "24px", color: "#131313" }} />
                ) : (
                  <PiMoonFill style={{ fontSize: "24px", color: "#fff" }} />
                )}
              </motion.div>
              <motion.div
                className={`border rounded-3xl w-[88px] h-[80px] relative p-4 flex flex-col justify-around gap-2 translate-y-[-195px] ${
                  isLightMode ? "bg-[#F1F5F9] border-[#94A3B8]" : "bg-[#202937] border-[#64748B]"
                }`}
                animate={isOpen ? { y: -200, x: -20 } : { y: 200, x: -20 }}
                initial={{ y: isOpen ? -150 : 150, x: -20 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <span
                  className="flex justify-center items-center gap-2 cursor-pointer z-10 translate-y-[-8px]"
                  onClick={() => toggleMode()}
                >
                  {isLightMode ? <PiMoon /> : <PiMoonFill />}
                  Dark
                </span>
                <span
                  className="flex justify-center items-center gap-2 cursor-pointer z-10 translate-y-[-5px]"
                  onClick={() => toggleMode()}
                >
                  {isLightMode ? <PiSunDimFill /> : <PiSun />}
                  Light
                </span>
                <motion.div
                  className={`rounded-3xl w-[95%] h-[35px] absolute top-1 left-0.5 ${
                    isLightMode ? "border-none bg-[#FCFCFC] text-black" : "border-white bg-[#334155]"
                  }`}
                  animate={{ y: isLightMode ? 34 : 0 }}
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                />
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
        <ul className="hidden lg:flex gap-10 md:translate-x-[-55px] items-center">
          <li className="hover:cursor-pointer">
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className="hover:cursor-pointer">
            <Link to="work" smooth={true} duration={500}>
              Work
            </Link>
          </li>
          <li className="hover:cursor-pointer">
            <Link to="about" smooth={true} duration={500}>
              About
            </Link>
          </li>
          <li className="hover:cursor-pointer">
            <Link to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
        <div
          className="translate-cd x-[-10px] md:translate-x-[-45px] lg:hidden z-10 hover:cursor-pointer hover:text-teal-300"
          onClick={() => setNav(!nav)}
        >
          {nav ? <h3>Close</h3> : <h3 className="translate-x-[-10px]">Menu</h3>}
        </div>
        <ul
          className={`${
            nav ? "text-white opacity-100 transform translate-x-0" : "opacity-0 transform -translate-y-full"
          } transition-transform absolute top-0 left-0 w-full h-screen bg-[#131313]/90 flex flex-col justify-center items-center text-2xl`}
          onClick={() => setNav(false)}
        >
          <li className="p-6 hover:cursor-pointer hover:text-teal-300">
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className="p-6 hover:cursor-pointer hover:text-teal-300">
            <Link to="work" smooth={true} duration={500}>
              Work
            </Link>
          </li>
          <li className="p-6 hover:cursor-pointer hover:text-teal-300">
            <Link to="about" smooth={true} duration={500}>
              About
            </Link>
          </li>
          <li className="p-6 hover:cursor-pointer hover:text-teal-300">
            <Link to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
