import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useTheme } from "../ThemeContext/ThemeContext"; // Import useTheme hook
import { useInView } from "react-intersection-observer";
import { LazyMotion, domAnimation, m } from "framer-motion";
import react from "../../assets/assets/react.png";
import node from "../../assets/assets/node.png";
import mongo from "../../assets/assets/mongo.png";
import javascript from "../../assets/assets/javascript.png";
import firebase from "../../assets/assets/firebase.png";
import figma from '../../assets/assets/figma.png';
import drawer from "../../assets/powerspotev/drawer.png";
import powerspotrecording from "../../assets/powerspotev/powerspotrecording.mp4";
import CoinCrowdScreenRecording from "../../assets/assets/coincrowd/CoinCrowdScreenRecording.mp4";
import nyxjoyas from "../../assets/assets/nyxjoyas/NYXJoyasRecording.mp4";
import easyspeak from "../../assets/assets/easyspeak/easyspeakrecording.mp4";
import yapcards from "../../assets/assets/yapcards/yapcards.mp4";
import Modal from "../Modal/Modal";

function ProjectTimeLine() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation triggers only once
    threshold: 0.1, // Adjust if needed to control when the animation starts
  });
  const { isLightMode } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  // Function to open the modal with the selected project
  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  // Adjust your closeModal function
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null); // Optionally reset selected project
  };

  const projectData = [
    {
      id: 1,
      industry: "Automotive",
      title: "PowerSpot EV",
      tech: ["React", "Node", "Firebase", "JavaScript", "Figma"],
      video: powerspotrecording,
      context:
        "Existing EV charging station locators weren't cutting it. To simplify the EV charging experience, I developed PowerSpot EV, a web app that goes beyond basic location services. By incorporating community-driven features like user reviews and personalized recommendations, PowerSpot EV fosters a vibrant ecosystem for EV drivers to connect and share valuable insights.",
      problem:
        "Existing EV charging apps fell short. They lacked detailed, real-time information and often provided a clunky user experience. My goal with PowerSpot EV was to address these pain points by creating a streamlined and informative platform for EV drivers. This involved overcoming key challenges, such as efficiently displaying a high density of charging stations (up to 30 within a 5-10 mile radius) without compromising app performance. Additionally, sourcing an API that offered comprehensive station details, including real-time availability and user feedback, proved to be a hurdle that demanded creative solutions.",
      solution:
        "Focusing on a seamless user experience, I built PowerSpot EV using React for a dynamic frontend and a RESTful API for efficient data handling. To ensure accurate and up-to-the-minute charging station information, I integrated Google Maps, Places, and NREL APIs, optimizing data fetching by 20%. For secure user management and data storage, I chose Google Firebase.",
      outcome:
        "PowerSpot EV is poised to revolutionize the EV charging experience. Success will be measured by user engagement, community growth, and feature adoption, with user satisfaction at its core. This project highlighted the importance of thoroughly evaluating multiple solutions before committing to a development path. Moving forward, I plan to leverage user feedback to drive future iterations, ensuring PowerSpot EV remains a valuable resource for the EV community.",
      subtitle:
        "Built an EV charging station discovery app with React and Google Maps API, improving data handling and user journey",
      link: "https://power-spot-ev.vercel.app/",
    },
    {
      id: 2,
      industry: "Language",
      title: "EasySpeak",
      tech: ["React", "Node", "Firebase", "JavaScript", "Figma"],
      video: easyspeak,
      context:
        "To break down language barriers, I developed EasySpeak, a mobile web app built with React Native. This user-friendly translation tool allows for seamless communication by providing quick and accurate translations of words, phrases, and sentences. As the sole full-stack developer, my focus was on creating an intuitive user interface, integrating the Google Translate API, and ensuring a smooth, efficient translation experience.",
      problem:
        "Integrating Google's Vision API to enable image translation presented a significant challenge. This complex feature, which allows users to snap a photo and translate the text within it, demanded careful implementation and impacted the project timeline.",
      solution:
        "To seamlessly integrate image translation, I immersed myself in Google's Vision API documentation and tapped into the collective wisdom of the developer community on Stack Overflow. This research-driven approach allowed me to effectively implement the feature, enhancing EasySpeak's functionality and user experience.",
      outcome:
        "EasySpeak users can now translate text from images with ease, thanks to the successful integration of Google's Vision API. This powerful feature has been met with positive feedback, with users appreciating its speed and convenience. This project underscored the importance of resourcefulness and a multi-faceted approach to problem-solving. By embracing challenges and exploring diverse solutions, I was able to deliver a valuable and user-friendly feature.",
      subtitle: "Breaking down language barriers with seamless mobile translation. 🗣️🌎✨",
    },
    {
      id: 3,
      industry: "Finance - Cryptocurrency",
      title: "CoinCrowd",
      tech: ["React", "Node", "Firebase", "JavaScript", "Figma"],
      video: CoinCrowdScreenRecording,
      context:
        "CoinCrowd is a web application built for the cryptocurrency community. It provides real-time crypto data, fosters engaging discussions, and allows users to create personalized portfolios. As the full-stack engineer, I spearheaded the development, integrating CoinGecko and TradingView APIs for data and visualization. Leveraging React, Tailwind CSS, and Firebase, I built a user-friendly platform with features like favoriting, portfolio creation, and community forums. Even the Giphy API was integrated to add a touch of fun!",
      problem:
        "Building CoinCrowd presented a trifecta of challenges: preventing information overload, seamlessly connecting user wallets and displaying their holdings, and efficiently integrating crypto news. To tackle these hurdles, I implemented a three-column grid layout for organized data presentation. While basic wallet connection is functional, complete synchronization is earmarked for future development. For news integration, TradingView's news widget offered a readily available and robust solution.",
      solution:
        "To tackle CoinCrowd's challenges, I combined the power of CoinGecko and TradingView APIs for comprehensive data and visualization. Using React, Tailwind CSS, and Firebase, I built a user-friendly platform with features like personalized portfolios and community discussions. Even the Giphy API was incorporated to add a touch of delight. This research-driven approach ensured an engaging and informative user experience.",
      outcome:
        "CoinCrowd successfully delivers a user-friendly platform for cryptocurrency enthusiasts of all levels. Key features like personalized portfolios and community forums have been praised for their ease of use and value, particularly by those new to crypto. The organized presentation of data ensures a clear and informative experience. This project reinforced the importance of structured development and organized code for managing complex projects. Future success will be gauged by user engagement, portfolio creation, and community interaction. Further development will prioritize refining wallet connectivity and expanding community features based on user feedback.",
      subtitle: "Real-time crypto data, insightful discussions, and personalized portfolio management hub. 📈💬💼",
      link: "https://cryptotracker-bf76c.web.app/",
    },
    {
      id: 4,
      industry: "Construction and Facility Management",
      title: "Yap.cards",
      tech: ["React", "Node", "Firebase", "JavaScript", "Figma"],
      video: yapcards,
      context:
        "Yapp.Cards aims to simplify communication and task management in construction and facility management. As the full-stack developer, I built a system using React, Node.js, and Firebase where QR codes link to specific tasks and dedicated chat windows. This allows for seamless communication and progress tracking between contractors, managers, and owners. I also included features like metadata tracking and multimedia integration to further enhance workflow efficiency.",
      problem:
        "The primary challenge was designing an intuitive and user-friendly application for contractors who were not tech-savvy. Since the entire web app relied heavily on contractor feedback and interaction, this presented a significant hurdle. To ensure successful adoption and utilization of the Yapp.Cards platform, it was crucial to prioritize simplicity and ease of use in every aspect of the design and development process.",
      solution:
        "To ensure a user-friendly experience for the contractors, I prioritized their feedback throughout the development process. Using Figma for collaborative design, I continuously refined the interface based on their input. On the backend, I leveraged Firebase for efficient data management, including tasks, QR codes, chat messages, and media uploads. This user-centric approach, combined with a robust backend, resulted in a simple yet powerful tool for streamlined communication and task management.",
      outcome:
        "This project successfully streamlined communication and task management in construction. By prioritizing user feedback, I created a simple, intuitive application that connects contractors and managers. This resulted in a streamlined workflow with increased efficiency. The project emphasized the importance of client communication and feedback throughout development. Future success will be measured by user adoption, task completion, communication volume, and user satisfaction.",
      subtitle: "Simplifying communication and task management in construction with QR code technology. 🚧🤝✨",
    },
    {
      id: 5,
      industry: "Retail - Jewelry",
      title: "NYX Joyas",
      tech: ["React", "Node", "Firebase", "JavaScript", "Figma"],
      video: nyxjoyas,
      context:
        "NYX Joyas, a retail business, needed an online platform to showcase and sell its products. I took on the role of full-stack developer and UI designer, building an e-commerce website with a user-friendly interface using React and Tailwind CSS. For the backend, I implemented secure user authentication and a comprehensive admin dashboard with Firebase, allowing the owner to fully manage products, categories, and images.",
      problem:
        "This project presented two key challenges: incorporating the client's desired hot pink color palette without compromising the visual appeal, and designing an effective layout to showcase diverse products. These considerations extended the project timeline slightly. However, the resulting 'bento box' layout for product categories brought a modern and fresh feel to the website.",
      solution:
        "To address the client's desired hot pink color scheme, I collaborated closely with them, exploring different pink palettes and discussing accessibility considerations. For the product categorization, I implemented a dynamic 'bento box' layout using CSS grid, showcasing products in varying sizes and ensuring responsiveness across different screen sizes. This approach resulted in a visually engaging and user-friendly online store.",
      outcome:
        "The final design, featuring a carefully chosen pink palette and a dynamic 'bento box' product layout, delighted the client and resulted in a 400% increase in website traffic within two days of launch. This success underscores the importance of client collaboration and user-centric design.",
      subtitle: "An e-commerce experience as radiant as the jewelry itself. ✨💍💎",
      link: "https://nyx-joyas.vercel.app/",
    },
  ];
  

  return (
    <LazyMotion features={domAnimation}> 
      <div
        className={`w-full h-auto px-2 pt-10 md:px-8 ${
          isLightMode ? "light-mode" : "dark-mode"
        } transition-all duration-500`}
        name="ProjectTimeLine"
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          project={selectedProject}
          projects={projectData}
          openModal={openModal}
        />
        <div className="flex gap-7 items-center p-4 md:p-[40px]">
          <div className="">
            <h2
              className={`text-4xl md:translate-x-2 ${
                isLightMode ? "text-black" : "text-white"
              }`}
            >
              Project TimeLine
            </h2>
          </div>
          <div>
            <div className="w-[188px] border border-[#334155]"></div>
          </div>
        </div>
        <VerticalTimeline>
          {projectData.map((project, index) => (
            <VerticalTimelineElement
              key={project.id}
              className="vertical-timeline-element--work px-4 py-10 md:px-20"
              contentStyle={{
                background: isLightMode ? "#FAFAFA" : "#131313",
                height: "650px",
                border: isLightMode ? "1px solid #0C4A6E" : "1px solid white",
                borderRadius: "20px",
                boxShadow: isLightMode
                  ? "0 4px 8px rgba(0, 0, 0, 0.1)"
                  : "0 4px 8px rgba(255, 255, 255, 0.1)",
              }}
              contentArrowStyle={{ borderRight: "7px solid rgb(33, 150, 243)" }}
              iconStyle={{
                background: isLightMode ? "#075985" : "#E0F2FE",
                color: "#fff",
              }}
            >
              <div className="flex flex-col gap-4">
                <h4
                  className={`text-sm ${
                    isLightMode ? "text-[#0369A1]" : "text-[#0EA5E9]"
                  }`}
                >{project.industry}</h4>
                <h3
                  className={`text-2xl ${
                    isLightMode ? "text-[#1E293B]" : "text-[#F1F5F9]"
                  }`}
                >
                  {project.title}
                </h3>
              </div>
              <div className="flex flex-wrap gap-2 pt-2 pb-2">
                {project.tech.map((tech) => (
                  <div
                    key={tech}
                    className={`w-auto h-[40px] flex justify-center items-center gap-2 border px-6 ${
                      isLightMode
                        ? "border-primary-800 text-[#6897b1]"
                        : "border-primary-100 text-[#E0F2FE]"
                    } rounded-full bg-opacity-20`}
                  >
                    <img
                      className="w-[15px] h-[15px]"
                      src={
                        tech === "React"
                          ? react
                          : tech === "Firebase"
                          ? firebase
                          : tech === "Node"
                          ? node
                          : tech === "Figma"
                          ? figma
                          : javascript
                      }
                      alt={tech}
                    />
                    <h4 className="text-sm">{tech}</h4>
                  </div>
                ))}
              </div>
              <div className="w-full my-2 relative">
                <video width="100%" autoPlay >
                  <source src={project.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="absolute bottom-[-155px]  w-[100%] flex flex-col justify-center items-center gap-6">
                  <p
                    className={`text-sm p-2 w-[90%] max-w-lg rounded-lg   ${
                      isLightMode ? "bg-[#F1F5F9]" : "bg-[#1E293B]/85"
                    }`}
                  >
                    {project.subtitle}
                  </p>
                  <div className="w-full max-w-lg flex justify-between pt-4 gap-3">
                    <button
                      className={`w-[150px] h-[40px] text-[#131313] border border-neutral-400 rounded-lg shadow-md shadow-zinc-700 flex justify-center items-center ${
                        isLightMode ? "bg-[#0F172A] text-white" : "bg-[#F8FAFC] text-[#131313]"
                      }`}
                      onClick={() => openModal(project)}
                    >
                      View Work
                    </button>
                    <button className="w-[150px] h-[40px] border-2 border-neutral-400 rounded-lg shadow-md shadow-zinc-700 flex justify-center items-center">
                      GitHub
                    </button>
                    <a href={project.link} target="_blank" rel="noopener noreferrer">
                      <button className="w-[150px] h-[40px] flex justify-center items-center">
                        Demo
                      </button>
                    </a>
                  </div>
                </div>
              </div>

            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
</LazyMotion>
  );
}

export default ProjectTimeLine;
