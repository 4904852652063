import React from "react";
import github from "../../assets/assets/github.png";
import linkedIn from "../../assets/assets/linkedIn.png";
import youtube from "../../assets/assets/youtube.png";
import x from "../../assets/assets/x.png";
import { MdOutlineMail } from "react-icons/md";

function Footer() {
  const gradientStyle = {
    background: `linear-gradient(270deg, #64748B 0%, #F1F5F9 100%)`, // Adjust colors as needed
    WebkitBackgroundClip: "text",
    color: "transparent",
  };

  return (
    <div className="w-full h-auto flex flex-col justify-center items-center bg-[#131313] p-8 border-t">
      {/* First Group Container */}
      <div className="w-full flex  ">
        {/* Footer Container */}
        <div className="w-full flex flex-wrap  items-center px-4  md:flex md:px-14 ">
          <div className="flex flex-col  w-[100%]">
            <div className="flex flex-wrap justify-between items-center  gap-3 text-[24px]">
              <div className="flex flex-wrap  gap-3">
              <h3>Alan Arias</h3> |{" "}
              <h3 style={gradientStyle}>Front End Engineer</h3>
              {/* Paragraph Container */}
              <p className="w-[300px] text-[#9CA3AF] text-[14px] md:text-[16px] lg:ml-[40px]">
                Full-Stack Engineer helping mission-driven orgs build scalable
                solutions
              </p>
              </div>
              {/* Socials */}
              <div className=" h-[30px] gap-8  hidden xl:flex">
                <div className="translate-y-[-4px]">
                  <MdOutlineMail size={30} />
                </div>
                <a href="https://www.linkedin.com/in/aarias11/">
                  <img
                    className="w-[24px] h-[24px]"
                    src={linkedIn}
                    alt="linkedin"
                  />
                </a>
                <a href="https://github.com/Aarias11">
                  <img
                    className="w-[24px] h-[24px]"
                    src={github}
                    alt="github"
                  />
                </a>
                <a>
                  <img
                    className="w-[54px] h-[54px] translate-y-[-15px] translate-x-[-15px]"
                    src={youtube}
                    alt="youtube"
                  />
                </a>
                <a href="https://twitter.com/Alancodes11">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="translate-x-[-35px]"
                  >
                    <g clip-path="url(#clip0_685_68138)">
                      <path
                        d="M18.9617 0.5H22.6405L14.5631 10.2601L24 23.5H16.5945L10.7964 15.4542L4.15861 23.5H0.47984L9.03699 13.0609L0 0.5H7.58947L12.8277 7.84981L18.9617 0.5ZM17.6741 21.2085H19.7134L6.51783 2.70664H4.32656L17.6741 21.2085Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_685_68138">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Horizontal Line */}
      <div className="w-full flex justify-center pt-5">
        <div className="w-[94%] md:w-[92%] border-t border-[#374151]"></div>
      </div>
      {/* 2nd Group Container */}
      <div className="w-full flex flex-col md:flex gap-3 px-4 md:px-14 pt-5">
        {/* Content Container */}
        <div className="w-full flex flex-col gap-6 sm:flex ">
          {/* Work, About, Let's Connect */}
          <div className="w-full flex  text-[12px] md:text-[16px] lg:text-[18px] gap-14  ">
            <button className="">Work</button>
            <button className="">About</button>
            <button className="">Let's Connect</button>
          </div>
          {/* SOCIALS ICONS */}
          <div className="socials w-full h-[30px] flex  xl:hidden">
            <div className="flex h-[30px] gap-8 ">
              <div className="translate-y-[-4px]">
                <MdOutlineMail size={30} />
              </div>
              <a href="https://www.linkedin.com/in/aarias11/">
                <img
                  className="w-[24px] h-[24px]"
                  src={linkedIn}
                  alt="linkedin"
                />
              </a>
              <a href="https://github.com/Aarias11">
                <img className="w-[24px] h-[24px]" src={github} alt="github" />
              </a>
              <a>
                <img
                  className="w-[54px] h-[54px] translate-y-[-15px] translate-x-[-15px]"
                  src={youtube}
                  alt="youtube"
                />
              </a>
              <a href="https://twitter.com/Alancodes11">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="translate-x-[-35px]"
                >
                  <g clip-path="url(#clip0_685_68138)">
                    <path
                      d="M18.9617 0.5H22.6405L14.5631 10.2601L24 23.5H16.5945L10.7964 15.4542L4.15861 23.5H0.47984L9.03699 13.0609L0 0.5H7.58947L12.8277 7.84981L18.9617 0.5ZM17.6741 21.2085H19.7134L6.51783 2.70664H4.32656L17.6741 21.2085Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_685_68138">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
        </div>
        {/* Copyright */}
        <div className="w-full  flex  text-[12px] mt-2 md:text-[14px] lg:text-[16px]">
          <p className="w-[300px] md:w-full flex  text-[#9CA3AF]">
            {" "}
            © 2024 Alan Arias. All rights reserved. Crafted with 🫶 in Brooklyn,
            NY, USA 🇺🇸 via 🇩🇴 🇪🇨
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
