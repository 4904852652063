import React, { useState } from "react";
import { useTheme } from "../ThemeContext/ThemeContext"; // Import useTheme hook
import emailjs from "emailjs-com";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Contact() {
  const { isLightMode } = useTheme();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // State for form inputs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // State for error messages
  const [errors, setErrors] = useState({});

  // Validate form
  const validateForm = () => {
    let tempErrors = {};
    if (!name) tempErrors.name = "Name is required";
    if (!email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email is invalid";
    }
    if (!message) tempErrors.message = "Message is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Prepare the template parameters
      const templateParams = {
        from_name: name,
        from_email: email,
        message: message,
      };

      // Replace 'your_service_id', 'your_template_id', and 'your_user_id' with your actual EmailJS values
      emailjs.send("service_gife7rv", "template_q2zhwoj", templateParams).then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Your message has been sent successfully.");
          // Reset form fields and errors
          setName("");
          setEmail("");
          setMessage("");
          setErrors({});
        },
        (err) => {
          console.log("FAILED...", err);
          alert("Failed to send the message, please try again.");
        }
      );
    }
  };

  const bounceAnimationVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 4.0,
        ease: "easeOut",
        bounce: 0.3,
        type: "spring",
      },
    },
  };

  return (
    <div
      className={`w-full h-auto px-2 md:px-8 pb-6 ${
        isLightMode ? "light-mode" : "dark-mode"
      } transition-all duration-500`}
      name="contact"
      ref={ref}
    >
      {/* Let's Connect Section Header */}
      <motion.div
        className="flex gap-7 items-center p-4 pt-10 md:p-[40px]"
        variants={bounceAnimationVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        transition={{ delay: 0.1 }}
      >
        <h2
          className={`text-4xl md:translate-x-2 ${
            isLightMode ? "text-black" : "text-white"
          }`}
        >
          Contact
        </h2>
        <div>
          <div className="w-[188px] border border-[#334155]"></div>
        </div>
      </motion.div>

      {/* Content Container */}
      <div className="w-full flex flex-col-reverse lg:flex lg:flex-row px-8">
        {/* Form Container */}
        <motion.div
          className="pt-4 p-4 md:p-4 lg:translate-x-8"
          variants={bounceAnimationVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          transition={{ delay: 0.2 }}
        >
          {/* Form */}
          <form
            className="w-full md:w-[50%] h-[100%] flex flex-col justify-center items-center gap-5"
            onSubmit={handleSubmit}
          >
            {/* Name Input */}
            <motion.div
              className="w-full md:w-full relative flex flex-col"
              variants={bounceAnimationVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ delay: 0.3 }}
            >
              <label className="py-1.5">Name</label>
              <div className="w-full md:w-full relative">
                <input
                  className={`w-full md:w-[620px] h-[40px] p-2 pl-10 text-zinc-200 rounded-lg relative ${
                    isLightMode
                      ? "border border-black bg-[#131313] text-[#b1bcca]"
                      : "text-zinc-800"
                  }`}
                  name="name"
                  type="text"
                  placeholder="Your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errors.name && (
                  <span className="error-messages text-red-600">
                    {errors.name}
                  </span>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="absolute top-2 left-3.5"
                >
                  <path
                    d="M15.75 6C15.75 8.07107 14.0711 9.75 12 9.75C9.92896 9.75 8.25002 8.07107 8.25002 6C8.25002 3.92893 9.92896 2.25 12 2.25C14.0711 2.25 15.75 3.92893 15.75 6Z"
                    stroke={isLightMode ? "#CBD5E1" : "#0F172A"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.50116 20.1182C4.57146 16.0369 7.9019 12.75 12 12.75C16.0983 12.75 19.4287 16.0371 19.4989 20.1185C17.2161 21.166 14.6764 21.75 12.0003 21.75C9.32402 21.75 6.78412 21.1659 4.50116 20.1182Z"
                    stroke={isLightMode ? "#CBD5E1" : "#0F172A"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </motion.div>

            {/* Email Input */}
            <motion.div
              className="w-full h-auto relative flex flex-col"
              variants={bounceAnimationVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ delay: 0.4 }}
            >
              <label className="pb-1.5">Email</label>
              <input
                className={`w-full md:w-[620px] h-[40px] p-2 pl-11 text-zinc-200 rounded-lg relative ${
                  isLightMode
                    ? "border border-black bg-[#131313] text-[#b1bcca]"
                    : "text-zinc-800"
                }`}
                name="email"
                type="email"
                placeholder="email@address.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <span className="error-messages text-red-600">
                  {errors.email}
                </span>
              )}
              <svg
                className="absolute top-10 left-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                alt="avatar"
              >
                <path
                  d="M21.75 6.75V17.25C21.75 18.4926 20.7426 19.5 19.5 19.5H4.5C3.25736 19.5 2.25 18.4926 2.25 17.25V6.75M21.75 6.75C21.75 5.50736 20.7426 4.5 19.5 4.5H4.5C3.25736 4.5 2.25 5.50736 2.25 6.75M21.75 6.75V6.99271C21.75 7.77405 21.3447 8.49945 20.6792 8.90894L13.1792 13.5243C12.4561 13.9694 11.5439 13.9694 10.8208 13.5243L3.32078 8.90894C2.65535 8.49945 2.25 7.77405 2.25 6.99271V6.75"
                  stroke={isLightMode ? "#CBD5E1" : "#0F172A"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </motion.div>

            {/* Message Input */}
            <motion.div
              className="w-full flex flex-col"
              variants={bounceAnimationVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ delay: 0.5 }}
            >
              <label className="py-1.5">Message</label>
              <textarea
                className={`w-full md:w-[620px] h-[200px] p-2 pl-4 text-zinc-200 rounded-lg relative ${
                  isLightMode
                    ? "border border-black bg-[#131313] text-[#b1bcca]"
                    : "text-zinc-800"
                }`}
                name="message"
                type="text"
                placeholder="Leave Alan a message"
                alt="envelope"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {errors.message && (
                <span className="error-messages text-red-600">
                  {errors.message}
                </span>
              )}
            </motion.div>
            <motion.div
              className="md:w-full"
              variants={bounceAnimationVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ delay: 0.6 }}
            >
              <button className="w-[300px] md:w-[620px] h-[60px] bg-gray-400 text-black border rounded-lg ">
                Send Message
              </button>
            </motion.div>
          </form>
        </motion.div>

        {/* Wording */}
        <motion.div
          className="sm:px-4 lg:px-11 pt-4 w-[100%] md:w-[53%] p-4"
          variants={bounceAnimationVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          transition={{ delay: 0.7 }}
        >
          <p
            className={`w-auto ${
              isLightMode ? "text-[#475569]" : "text-[#CBD5E1]"
            }`}
          >
            Whether you have a question or just want to say hi, my inbox is
            always open. Looking forward to hearing from you!
          </p>
          <br />
          <p
            className={`${
              isLightMode ? "sm:text-[#475569]" : "sm:text-[#CBD5E1]"
            }`}
          >
            For a straight shot to my inbox: alan.arias11@gmail.com
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default Contact;
