import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "../ThemeContext/ThemeContext";
import { PiArrowRight, PiArrowLeft } from "react-icons/pi";
import react from "../../assets/assets/react.png";
import node from "../../assets/assets/node.png";
import mongo from "../../assets/assets/mongo.png";
import firebase from "../../assets/assets/firebase.png";
import figma from "../../assets/assets/figma.png";
import javascript from "../../assets/assets/javascript.png";

function Modal({ isOpen, onClose, project, projects, openModal }) {
  const [detailToShow, setDetailToShow] = useState("context");
  const [isLoading, setIsLoading] = useState(true);
  const { isLightMode } = useTheme();

  const [currentProject, setCurrentProject] = useState(project);
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    setCurrentProject(project);
    if (isOpen) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [project, isOpen]);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.3, type: "ease out back", stiffness: 150 },
    },
  };

  const slideVariants = {
    initial: (direction) => ({
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    }),
    animate: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
    exit: (direction) => ({
      x: direction < 0 ? 300 : -300,
      opacity: 0,
      transition: { duration: 0.5 },
    }),
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  const nextProject = () => {
    const currentIndex = projects.findIndex((p) => p.id === currentProject.id);
    const nextIndex = (currentIndex + 1) % projects.length;
    const nextProject = projects[nextIndex];
    setDirection(1); // Set direction for animation
    setCurrentProject(nextProject);
    openModal(nextProject);
  };

  const prevProject = () => {
    const currentIndex = projects.findIndex((p) => p.id === currentProject.id);
    const prevIndex = (currentIndex - 1 + projects.length) % projects.length;
    const prevProject = projects[prevIndex];
    setDirection(-1); // Set direction for animation
    setCurrentProject(prevProject);
    openModal(prevProject);
  };

  return (
    <AnimatePresence custom={direction}>
      {isOpen && currentProject && (
        <motion.div
          className="fixed inset-0 z-50 top-0 overflow-auto bg-[#47556980] bg-opacity-50"
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.div
            className="modal-overlay fixed inset-0 z-40 flex justify-center md:flex md:items-center md:justify-center top-14 backdrop-filter backdrop-blur-xl "
            variants={modalVariants}
            onClick={handleClickOutside}
          >
            <motion.div
              className={`modal-content  ${
                isLightMode ? "bg-white" : "bg-[#131313]"
              } rounded-lg p- w-[500px] h-auto md:w-[85%] md:h-[95%] lg:w-[89%] lg:h-[95%] relative flex flex-col overflow-y-scroll`}
              variants={modalVariants}
              transition={{ duration: 0.3 }}
            >
              <div className="w-full flex justify-center sticky top-0 z-50 ">
                <div
                  className={`w-[95%] md:w-[83%] flex justify-end z-40   ${
                    isLightMode ? "bg-[#fcfcfc]" : "bg-[#131313]"
                  } `}
                >
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={80}
                      height={20}
                      style={{
                        borderRadius: "4px",
                        marginRight: "8px",
                        marginTop: "20px",
                      }}
                      sx={{ bgcolor: "grey.900" }}
                    />
                  ) : (
                    <button
                      onClick={onClose}
                      className="flex translate-x-[-9px] py-4 "
                    >
                      <span
                        className={`pt-3 ${
                          isLightMode ? "text-[#131313]" : "text-[#fcfcfc]"
                        }`}
                      >
                        Close
                      </span>
                    </button>
                  )}
                </div>
              </div>

              <motion.div
                key={currentProject.id}
                custom={direction}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={slideVariants}
                className="px-3 md:flex md:justify-center"
              >
                <div className="w-[83%] ">
                  {isLoading ? (
                    <>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        width={210}
                        height={60}
                        sx={{ bgcolor: "grey.900" }}
                      />
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={340}
                        height={40}
                        sx={{ bgcolor: "grey.900" }}
                      />
                    </>
                  ) : (
                    <>
                      <h4 className="translate-y-[30px] text-[#0EA5E9]">
                        {currentProject.industry}
                      </h4>
                      <h3 className="text-[30px] pt-8">
                        {currentProject.title}
                      </h3>
                    </>
                  )}
                </div>
              </motion.div>

              <motion.div
                key={`${currentProject.id}-tech`}
                custom={direction}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={slideVariants}
                className="px-3 md:flex md:justify-center"
              >
                <div className="flex w-[83%] gap-2 pt-3">
                  {isLoading ? (
                    <>
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={40}
                        animation="wave"
                        style={{ borderRadius: "20px" }}
                        sx={{ bgcolor: "grey.900" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={40}
                        animation="wave"
                        style={{ borderRadius: "20px" }}
                        sx={{ bgcolor: "grey.900" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={120}
                        height={40}
                        animation="wave"
                        style={{ borderRadius: "20px" }}
                        sx={{ bgcolor: "grey.900" }}
                      />
                    </>
                  ) : (
                    <>
                      {currentProject.tech.map((tech) => (
                        <div
                          key={tech}
                          className={`w-auto h-[40px] p-4  flex justify-center items-center gap-2 border ${
                            isLightMode
                              ? "border-primary-800 text-[#075985]"
                              : "border-primary-100 text-[#E0F2FE]"
                          } rounded-full`}
                        >
                          <img
                            className="w-[15px] h-[15px]"
                            src={
                              tech === "React"
                                ? react
                                : tech === "Firebase"
                                ? firebase
                                : tech === "Node"
                                ? node
                                : tech === "Figma"
                                ? figma
                                : javascript
                            }
                            alt={tech}
                          />
                          <h4 className="text-sm">{tech}</h4>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </motion.div>

              <motion.div
                key={`${currentProject.id}-video`}
                custom={direction}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={slideVariants}
                className="w-full flex flex-col justify-center items-center h-auto"
              >
                <div className="w-full flex justify-center p-4 md:h-[50%]">
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{
                        bgcolor: "grey.900",
                        width: "100%",
                        height: "300px",
                        "@media (min-width: 768px)": {
                          height: "500px",
                          width: "84%",
                        },
                      }}
                    />
                  ) : (
                    <>
                      {currentProject.video ? (
                        <video
                          key={`${currentProject.id}-${currentProject.video}`}
                          width="100%"
                          autoPlay
                          // controls
                          className="md:w-[100%] md:h-[500px] object-contain rounded-md"
                        >
                          <source src={currentProject.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          className="w-auto md:w-[84%] md:h-[500px] object-contain rounded-md"
                          alt="Project"
                          src={currentProject.image}
                        />
                      )}
                    </>
                  )}
                </div>

                <div className="w-full md:w-[650px] md:h-[50%] flex flex-col gap-10  justify-end items-center p-4 translate-y-[-50px] md:translate-y-[-45px]">
                  {isLoading ? (
                    <>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        width="60%"
                        height={20}
                        style={{ borderRadius: "20px" }}
                        sx={{ bgcolor: "grey.900" }}
                      />
                    </>
                  ) : (
                    <p
                      className={`text-sm w-[95%] h-auto translate-y-[-3px] p-3 rounded-lg ${
                        isLightMode
                          ? "bg-[#F1F5F9] border border-primary-[#94A3B8] text-[#475569]"
                          : "bg-[#1E293B]/95 border border-[#64748B] text-[#CBD5E1]"
                      }`}
                    >
                      {currentProject.subtitle}
                    </p>
                  )}

                  <div className="flex flex-col gap-3 text-sm w-auto h-[210px] ">
                    <div className="w-auto flex justify-around gap-22   items-center md:[800px]">
                      {isLoading ? (
                        <>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={80}
                            height={30}
                            style={{ borderRadius: "20px" }}
                            sx={{ bgcolor: "grey.900" }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={80}
                            height={30}
                            style={{ borderRadius: "20px" }}
                            sx={{ bgcolor: "grey.900" }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={80}
                            height={30}
                            style={{ borderRadius: "20px" }}
                            sx={{ bgcolor: "grey.900" }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={80}
                            height={30}
                            style={{ borderRadius: "20px" }}
                            sx={{ bgcolor: "grey.900" }}
                          />
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => setDetailToShow("context")}
                            className={
                              detailToShow === "context"
                                ? "active"
                                : "text-[#94A3B8]"
                            }
                          >
                            Context
                          </button>
                          <button
                            onClick={() => setDetailToShow("problem")}
                            className={
                              detailToShow === "problem"
                                ? "active"
                                : "text-[#94A3B8]"
                            }
                          >
                            Problem
                          </button>
                          <button
                            onClick={() => setDetailToShow("solution")}
                            className={
                              detailToShow === "solution"
                                ? "active"
                                : "text-[#94A3B8]"
                            }
                          >
                            Solution
                          </button>

                          <button
                            onClick={() => setDetailToShow("outcome")}
                            className={
                              detailToShow === "outcome"
                                ? "active"
                                : "text-[#94A3B8]"
                            }
                          >
                            Outcome
                          </button>
                        </>
                      )}
                    </div>

                    <div className="w-auto lg:w-[800px] h-[175px] p-3">
                      {isLoading ? (
                        <>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            height={20}
                            style={{ marginBottom: 6 }}
                            sx={{ bgcolor: "grey.900" }}
                          />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width="80%"
                            height={20}
                            style={{ marginBottom: 6 }}
                            sx={{ bgcolor: "grey.900" }}
                          />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width="60%"
                            height={20}
                            sx={{ bgcolor: "grey.900" }}
                          />
                        </>
                      ) : (
                        <div className="w-[423px] md:w-full ">
                          {detailToShow === "context" && (
                            <p className="p-3">{currentProject.context}</p>
                          )}
                          {detailToShow === "problem" && (
                            <p className="p-3">{currentProject.problem}</p>
                          )}
                          {detailToShow === "solution" && (
                            <p className="p-3">{currentProject.solution}</p>
                          )}
                          {detailToShow === "outcome" && (
                            <p className="p-3">{currentProject.outcome}</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="flex justify-center gap-2">
                      {isLoading ? (
                        <>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={120}
                            height={40}
                            style={{ borderRadius: "4px" }}
                            sx={{ bgcolor: "grey.900" }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={100}
                            height={40}
                            style={{ borderRadius: "4px" }}
                            sx={{ bgcolor: "grey.900" }}
                          />
                        </>
                      ) : (
                        <>
                          <button className="w-[120px] p-[10px] border border-[#94A3B8] rounded-lg shadow-md shadow-zinc-700 flex justify-center items-center">
                            <span className="text-base">GitHub</span>
                          </button>
                          <a
                            href={currentProject.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button className="w-[100px] h-[40px] flex justify-center items-center">
                              <p className="text-base">Demo</p>
                            </button>
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>

              <div className="w-full h-[50px] flex border-t border-[#334155] justify-center sticky bottom-0">
                <div
                  className={`w-[95%] md:w-[84%] h-[50px] pt-3 md:px-3 flex justify-between ${
                    isLightMode
                      ? "bg-[#fcfcfc] text-[#131313]"
                      : "bg-[#131313]/95 text-white"
                  }`}
                >
                  {isLoading ? (
                    <>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={120}
                        height={40}
                        style={{ borderRadius: "4px" }}
                        sx={{ bgcolor: "grey.900" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={120}
                        height={40}
                        style={{ borderRadius: "4px" }}
                        sx={{ bgcolor: "grey.900" }}
                      />
                    </>
                  ) : (
                    <>
                      <button onClick={prevProject} className="flex gap-1">
                        <PiArrowLeft size={24} />
                        <span
                          className={`text-zinc-800 ${
                            isLightMode
                              ? "text-[#131313]"
                              : "text-[#F8FAFC] dark:text-[#F8FAFC]"
                          }`}
                        >
                          Previous
                        </span>
                      </button>

                      <button onClick={nextProject} className="flex gap-1">
                        <span
                          className={`text-zinc-800 ${
                            isLightMode
                              ? "text-[#131313]"
                              : "text-[#F8FAFC] dark:text-[#F8FAFC]"
                          }`}
                        >
                          Next
                        </span>
                        <PiArrowRight size={24} />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Modal;
