import React, { useEffect, useState, useRef } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useTheme } from "../ThemeContext/ThemeContext"; // Import useTheme hook
import Navbar from "../Navbar/Navbar";
import { Link } from "react-scroll";

const texts = ["curious", "creative"];

function Home() {
  const { isLightMode, toggleMode } = useTheme();
  const controls = useAnimation(); // Define the controls for animation
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start("visible"); // Trigger animation when component is in view
          }
        });
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [controls]);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000); // Change text every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);

  const switchVariants = {
    hidden: (index) => ({
      opacity: 0,
      x: -10, // Initial position off to the left
      transition: {
        delay: index * 0.1, // Delay each letter by 0.1 second
      },
    }),
    visible: (index) => ({
      opacity: 1,
      x: 0, // Move to its original position
      transition: {
        duration: 0.8,
        ease: "easeInOut",
        delay: index * 0.1, // Delay each letter by 0.1 second
      },
    }),
    exit: { opacity: 0, x: -10 }, // Move off to the left when exiting
  };


  const gradientStyle = {
    background: `linear-gradient(270deg, #64748B 0%, #F1F5F9 100%)`, // Adjust colors as needed
    WebkitBackgroundClip: "text",
    color: "transparent",
  };

  return (
    <div
      name="home"
      className={`w-full h-[760px] md:h-screen relative px-2  md:px-6 ${
        isLightMode ? "light-mode" : "dark-mode"
      } transition-all duration-500`}
    >
      {/* <Navbar /> */}

      {/* MAIN CONTENT CONTAINER */}
      <div className="container flex flex-col justify-center  md:p-10 ">
        {/* Circle Top-Right */}
        <motion.div
          className="hidden fixed top-0 right-0 opacity-30 blur-3xl"
          style={{
            x: [0, -250], // Animate from current position to -250px horizontally
            y: [-250, 0], // Animate from -250px vertically to current position
            borderRadius: "50%", // Make it a circle
            background: "#64748B",
            width: 300,
            height: 300,
          }}
          transition={{
            duration: 1, // Animation duration
          }}
        />
        {/* Circle Bottom-Left */}
        <div className="hidden translate-y-[50px] translate-x-[-350px] md:translate-y-[250px] md:translate-x-[-250px] bottom-0 left-0 absolute opacity-30 blur-3xl">
          <div className="border w-[650px] h-[600px] rounded-full bg-slate-700 "></div>
        </div>
        {/* Available to Work button */}
        <div className="pt-10">
          <motion.div className="px-5">
            <motion.button
              className={`border w-[200px] h-[32px] rounded-full p-2 flex gap-4 items-center ${
                isLightMode ? "border-black text-[#131313]" : ""
              }`}
              initial={{ y: 25, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.75 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                className="absolute animate-pulse"
              >
                <circle opacity="0.4" cx="7" cy="7" r="7" fill="#4ADE80" />
                <circle cx="7" cy="7" r="4" fill="#4ADE80" />
              </svg>
              <span
                className={` ${
                  isLightMode
                    ? "text-[#15803D] translate-x-[20px]"
                    : "text-[#F0FDF4] translate-x-[20px]"
                }`}
              >
                Available for Projects
              </span>
            </motion.button>
          </motion.div>

          {/* What Am I - Software Engineer */}

          <motion.div
            className="text-2xl w-auto md:text-4xl lg:text-5xl pt-16 px-4 relative"
            initial={{ y: 25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.75 }}
          >
            <p className="tracking-tight text-[40px]">
              <span className="flex gap-3 text-5xl md:text-7xl">
                Alan is{" "}
                <AnimatePresence mode="wait">
                  <motion.div
                    key={texts[index]}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="text-5xl w-[85px]  md:w-[130px] lg:w-[180px] md:text-7xl  lg:text-7xl lg:translate-y-[1px]"
                  >
                    {texts[index].split("").map((letter, index) => (
                      <motion.span
                        key={index}
                        custom={index} // Pass the index to the variant function
                        variants={switchVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                      >
                        {letter}
                      </motion.span>
                    ))}
                  </motion.div>{" "}
                </AnimatePresence>
              </span>
              <br />
              <br />
              <motion.p
                className={`text-2xl xl:w-[1000px] ${
                  isLightMode ? "text-black" : "gradient-style"
                }`}
                style={isLightMode ? {} : gradientStyle}
                initial={{ y: 25, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.75 }}
              >
                Front End Software Engineer who solves mission-driven problems
                with clean crafted code and optimized outcomes.
              </motion.p>
            </p>
          </motion.div>

          {/* Buttons - View Work, Resume, and About */}
          <motion.div
            className="w-full pt-10 flex flex-col justify-center items-center gap-7 md:gap-10 md:w-[600px] md:flex md:flex-row  "
            initial={{ y: 25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.75 }}
          >
            {/* View Work */}

            <Link to="ProjectTimeLine" smooth={true} duration={500}>
            
            <button className="w-[141px] h-[45px] md:w-[160px] md:h-[55px] border-neutral-300 bg-neutral-50 text-black text-sm rounded-lg shadow-lg">
              View Work
            </button>
            </Link>

            {/* Resume */}
            <a
  href="https://drive.google.com/file/d/1AfvoFNYCb6zZiBpLdCY8nu3tmfgSQMQF/view?usp=sharing"
  target="_blank"
  rel="noopener noreferrer"
>
  <button className="flex items-center justify-center gap-10 w-[141px] h-[45px] md:w-[160px] md:h-[55px] text-sm border-2 rounded-lg p-3 border-neutral-400">
    Resumé
  </button>
</a>


            {/* About */}
            <Link to="about" smooth={true} duration={500}>
            <button className="md:w-[160px] md:h-[55px] ">About</button>
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Home;
