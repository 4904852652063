import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  // Initialize state with theme mode from localStorage if it exists
  const [isLightMode, setIsLightMode] = useState(() => {
    const savedMode = localStorage.getItem('isLightMode');
    // Convert string to boolean; if null, default to false
    return savedMode ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    // Save the current theme mode to localStorage whenever it changes
    localStorage.setItem('isLightMode', JSON.stringify(isLightMode));
  }, [isLightMode]);

  const toggleMode = () => {
    setIsLightMode(prevMode => !prevMode);
  };

  return (
    <ThemeContext.Provider value={{ isLightMode, toggleMode }}>
      {children}
    </ThemeContext.Provider>
  );
}
