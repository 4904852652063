import React from "react";
import Home from "../Home/Home";
import Skills from "../Skills/Skills";
import ProjectTimeLine from "../ProjectTimeLine/ProjectTimeLine";
import About from "../About/About";
import Contact from "../Contact/Contact";

function LandingPage() {
  return (
    <div className="w-full">
      <Home />
      <Skills />
      <ProjectTimeLine />
      <About />
      <Contact />   
    </div>
  );
}

export default LandingPage;
