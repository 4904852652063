import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { ThemeProvider } from "./components/ThemeContext/ThemeContext";
import LandingPage from "./components/LandingPage/LandingPage";
import Loading from "./components/Loading/Loading";
import Footer from "./components/Footer/Footer";
import Error400 from "./pages/ErrorPagesFolder/Error400";
import Error401 from "./pages/ErrorPagesFolder/Error401";
import Error403 from "./pages/ErrorPagesFolder/Error403";
import Error405 from "./pages/ErrorPagesFolder/Error405";
import Error408 from "./pages/ErrorPagesFolder/Error408";
import Error409 from "./pages/ErrorPagesFolder/Error409";
import Error410 from "./pages/ErrorPagesFolder/Error410";
import Error429 from "./pages/ErrorPagesFolder/Error429";
import Error504 from "./pages/ErrorPagesFolder/Error504";
import ErrorPage from "./pages/ErrorPagesFolder/ErrorPage";
import { LazyMotion, domAnimation } from "framer-motion"
import { Analytics } from '@vercel/analytics/react';

function App() {
  const [isLightMode, setIsLightMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const location = useLocation();

  const toggleMode = () => {
    setIsLightMode((prevMode) => !prevMode);
  };

  // Loading Progress bar
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(interval);
          setLoading(false); // Set loading to false when progress reaches 100%
          return 100;
        } else {
          return prevProgress + 1; // Increment progress by 10% every second
        }
      });
    }, 10);

    // Simulate loading time for the landing page
    setTimeout(() => {
      setLoading(false);
    }, 300000); // Adjust the time as needed

    return () => clearInterval(interval);
  }, []);

  // Check if the current location is the landing page
  const isLandingPage = location.pathname === "/";

  if (loading && isLandingPage) {
    return <Loading progress={progress} />;
  }


  return (
    <LazyMotion features={domAnimation}> 
    <ThemeProvider value={{ isLightMode, toggleMode }}>
      <div className={`App ${isLightMode ? "light-mode" : "dark-mode"}`}>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<ErrorPage />} />

          <Route path="/400" element={<Error400 />} />
          <Route path="/401" element={<Error401 />} />
          <Route path="/403" element={<Error403/>} />
          <Route path="/405" element={<Error405 />} />
          <Route path="/408" element={<Error408 />} />
          <Route path="/409" element={<Error409 />} />
          <Route path="/410" element={<Error410 />} />
          <Route path="/429" element={<Error429 />} />
          <Route path="/504" element={<Error504 />} />

        </Routes>
        <Analytics />
        <Footer />
      </div>
    </ThemeProvider>
    </LazyMotion>
  );
}

export default App;
