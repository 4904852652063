import React from "react";

function Loading({ progress }) {
  return (
    <div className="flex flex-col h-screen justify-center items-center bg-black">
      <div className="w-[224px] sm:w-[321px] md:w-[501px] p-3 rounded-md flex flex-col">
        <div className="sm: h-[4px] md:h-[8px] bg-[#1E293B] rounded-full relative">
          {/* Progress Bar */}
          <div
            className="h-[4px] md:h-[8px] bg-[#0284C7]/100 rounded-full bar shadow-lg shadow-blue-200"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        {/* Loading... Text */}
        {/* 24 28 32 pt for loader*/}
        <div className="h-auto text-center  pt-4 md:pt-5 lg:pt-6">
          <span className="text-[#94A3B8] text-[16px] sm:text-[18px] md:text-[20px] ">Loading </span>
          {progress === 100 ? (
            <span className="text-[#F8FAFC] text-[16px] md:text-[20px]">Complete</span>
          ) : (
            <span className="text-[#F8FAFC] font-medium text-[16px] sm:text-[18px] md:text-[20px]">{progress}%</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Loading;