import React from "react";
import html5 from "../../assets/assets/html5.png";
import css from "../../assets/assets/css.png";
import javascript from "../../assets/assets/javascript.png";
import firebase from "../../assets/assets/firebase.png";
import react from "../../assets/assets/react.png";
import github from "../../assets/assets/github.png";
import tailwind from "../../assets/assets/tailwind.png";
import node from "../../assets/assets/node.png";
import mongo from "../../assets/assets/mongo.png";
import python from "../../assets/assets/python.png";
import mysql from "../../assets/assets/mysql.png";
import postgresql from "../../assets/assets/postgresql.png";
import figma from "../../assets/assets/figma.png";
import vite from "../../assets/assets/vite.png";
import postman from "../../assets/assets/postman.png";
import bootstrap from "../../assets/assets/bootstrap.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useTheme } from "../ThemeContext/ThemeContext"; // Import useTheme hook


export default function Skills() {
    const { isLightMode } = useTheme();
    const { ref, inView } = useInView({
      triggerOnce: true, // Ensures animation triggers only once
      threshold: 0.1,  // Adjust if needed to control when the animation starts≈
    });
  
    const fadeInAnimationVariants = {
      hidden: {
        opacity: 0,
        y: 50 // Adjust for desired initial position
      },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 2.0, // Adjust duration as needed
          ease: "easeInOut",
        }
      }
    };
  
    const skills = [
      { src: html5, name: "HTML" },
      { src: css, name: "CSS" },
      { src: javascript, name: "JavaScript" },
      { src: tailwind, name: "Tailwind CSS" },
      { src: react, name: "REACT" },
      { src: node, name: "Node" },
      { src: firebase, name: "Firebase" },
      { src: mongo, name: "MongoDB" },
      { src: github, name: "GitHub" },
      { src: python, name: "Python" },
      { src: mysql, name: "MySQL" },
      { src: postgresql, name: "PostgreSQL" },
      { src: figma, name: "Figma" },
      { src: vite, name: "Vite" },
      { src: postman, name: "Postman" },
      { src: bootstrap, name: "Bootstrap" },
    ];
  
    return (
      <div
        className={`w-full h-auto px-2 md:px-8 ${isLightMode ? "light-mode" : "dark-mode"} transition-all duration-500`}
        name="work"
        ref={ref}
      >
        {/* Skills Section showcasing Tech Stack */}
        <div className="flex gap-7 items-center p-4 md:p-[40px]">
          <div className="">
            <h2 className={`text-4xl md:translate-x-2 ${isLightMode ? "text-black" : "text-white "}`}>
              Skills
            </h2>
          </div>
          <div>
            <div className="w-[188px] border border-[#334155]"></div>
          </div>
        </div>
  
        {/* Skills Container */}
        <div className="w-full flex justify-center px-4">
          <div className="w-[430px] sm:[420px] md:w-[100%] h-auto pt-10 grid grid-cols-3 md:grid-cols-6 gap-[24px] md:p-[40px] md:place-items-center">
            {skills.map((skill, index) => (
              <motion.div
                className={`w-[80px] h-[80px] md:w-[100px] md:h-[100px] lg:w-[120px] lg:h-[120px] flex flex-col justify-center items-center border ${isLightMode ? 'border-neutral-500' : 'border-neutral-400'} shadow-md shadow-black rounded-lg md:rounded-xl lg:rounded-2xl`}
                variants={fadeInAnimationVariants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                transition={{ delay: 0.05 * index }}
                key={skill.name}
              >
                <img
                  className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] lg:w-[70px] lg:h-[70px]"
                  src={skill.src}
                  alt={skill.name}
                />
                <h3 className="p-2 text-[10px] md:text-[12px] lg:text-[14px]">
                  {skill.name}
                </h3>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  